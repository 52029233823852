import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import SEO from "../components/seo";
import { StyledSiteMapPage } from "./site-map-page.styles";
import MenuRenderer from "../navigation/menu-renderer";
import { SiteMapQueryQuery } from "../../gatsby-graphql";

export interface ISiteMapPageProps {}

const SiteMapPageTemplate:React.FC<ISiteMapPageProps> = props => {
  const data = useStaticQuery<SiteMapQueryQuery>(graphql`
    query SiteMapQuery {
    allWpMenuItem(filter: { locations: { eq: SITEMAP } } ) {
          nodes {
            ...MenuItemsFragment
          }
        }
    }
  `);

  const siteMap = data.allWpMenuItem;

  return (
    <StyledSiteMapPage className="template template--site-map">
      <SEO title={'Site Map'} description={'Safety Fist Site Map'} />
      <h1 className="site-map__title">Site Map</h1>
      <div className="site-map__content">
        {!!siteMap?.nodes && siteMap.nodes !== undefined &&
          <MenuRenderer menuItems={siteMap.nodes} listItemClass="site-map__page" />
        }
      </div>
    </StyledSiteMapPage>
  )
}

export default SiteMapPageTemplate;
