import styled from 'styled-components';
import { media } from '../global/media.styles';
import { spacing, blockSpacing } from '../global/spacing.styles';
import { h1Styles, bragParagraphStyles, quoteStyles, LinkStyles } from '../global/typography.styles';

export const StyledSiteMapPage = styled.div`
    padding-top: ${spacing.pageTopPadding}px;

    & .site-map {

        &__title {
            ${h1Styles}
            ${blockSpacing}
        }

        &__content {
            ${blockSpacing}
            margin-bottom: ${spacing.blockPaddingYDesktop};
        }

        &__page {
            ${bragParagraphStyles}
            line-height: 60px;

            @media ${media.desktop} {
                line-height: 70px;
            }

            &--sub-item {
                ${quoteStyles}
            }

            a {
                ${LinkStyles}
            }
        }
    }
`;
